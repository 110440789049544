import {
  CREATE_DELIVERY,
  ASSIGN_DRIVER,
  COMPLETE_DELIVERY,
  UPDATE_DELIVERY,
  REOPEN_DELIVERY,
  SEND_NOTIFICATION_TO_DRIVER,
} from '@/graphql/mutations';
import { GET_DELIVERIES } from '@/graphql/queries';
import { IDelivery } from '@/interfaces/IDelivery.interface';
import { IPaginatedVariables } from '@/interfaces/IPageInfo.interface';
import { Component, Vue } from 'vue-property-decorator';
import { formatDistance, formatDistanceStrict } from 'date-fns';

@Component
export class DeliveriesMixin extends Vue {
  async getDeliveries(variables: IPaginatedVariables = { first: 50, search: `%%` }) {
    const { data } = await this.$apollo.query({
      query: GET_DELIVERIES,
      variables,
      fetchPolicy: 'network-only',
    });

    return data.deliveries;
  }

  async createDelivery(delivery: Partial<IDelivery>) {
    try {
      const { data } = await this.$apollo.mutate({
        mutation: CREATE_DELIVERY,
        variables: delivery,
      });

      return data.createOneDelivery;
    } catch (e) {
      this.$notify({
        group: 'main',
        type: 'error',
        title: 'Error',
        text: '',
      });
    }
  }

  async updateDelivery(delivery: Partial<IDelivery>) {
    const { data } = await this.$apollo.mutate({
      mutation: UPDATE_DELIVERY,
      variables: delivery,
    });

    return data.updateOneDelivery;
  }

  async assignDriver(deliveryId: number, driverId: number) {
    try {
      const { data } = await this.$apollo.mutate({
        mutation: ASSIGN_DRIVER,
        variables: {
          deliveryId,
          driverId,
        },
      });

      return data.updateOneDelivery;
    } catch (e) {
      this.$notify({
        group: 'main',
        type: 'error',
        title: 'Error',
        text: '',
      });
    }
  }

  async sendNotificationToDriver(deliveryId: number) {
    const { data } = await this.$apollo.mutate({
      mutation: SEND_NOTIFICATION_TO_DRIVER,
      variables: {
        deliveryId,
      },
    });

    return data.sendToDriver;
  }

  async completeDelivery(deliveryId: number) {
    const { data } = await this.$apollo.mutate({
      mutation: COMPLETE_DELIVERY,
      variables: {
        deliveryId,
      },
    });

    return data.updateOneDelivery;
  }

  async reopenDelivery(deliveryId: number) {
    const { data } = await this.$apollo.mutate({
      mutation: REOPEN_DELIVERY,
      variables: {
        deliveryId,
      },
    });

    return data.updateOneDelivery;
  }

  calculateTimeSinceOrdered(delivery: IDelivery) {
    if (delivery.createdAt) {
      return formatDistance(new Date(delivery.createdAt), new Date(), { addSuffix: true });
    }
    return '';
  }

  calculateTimeToDeliver(createdAt: string, deliveredAt: string) {
    return formatDistanceStrict(new Date(createdAt), new Date(deliveredAt));
  }
}
