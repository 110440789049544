import { ProductNames } from '../interfaces/IProduct.interface';
import { Vue, Component } from 'vue-property-decorator';
import { gmapApi } from 'gmap-vue';
import { ILocation } from '@/interfaces';

@Component
export class GoogleMapsMixin extends Vue {
  // Computed
  get google() {
    return gmapApi();
  }

  filterAddresses(
    addresses: google.maps.GeocoderResult[],
    type = 'route',
  ): google.maps.GeocoderResult[] {
    // Remove addresses without street names
    const filteredAddresses: google.maps.GeocoderResult[] = [];

    addresses.forEach((a: google.maps.GeocoderResult) => {
      // Only return address components where the type matches
      if (a.address_components.find(ac => ac.types.includes(type))) {
        // Make sure we dont add any duplicate addresses
        if (!filteredAddresses.some(fa => fa.formatted_address === a.formatted_address)) {
          filteredAddresses.push(a);
        }
      }
    });

    return filteredAddresses;
  }

  // Address Components
  getComponent(
    addressComponents: google.maps.GeocoderAddressComponent[],
    type: string,
  ): google.maps.GeocoderAddressComponent {
    const component = addressComponents.find(c => c.types.includes(type));

    return component || { long_name: '', short_name: '', types: [] };
  }

  addressComponentsToLocation(addressComponents): Partial<ILocation> {
    const streetNumber = this.getComponent(addressComponents, 'street_number');
    const streetAddress = this.getComponent(addressComponents, 'route');
    const province = this.getComponent(addressComponents, 'administrative_area_level_1');
    const postal = this.getComponent(addressComponents, 'postal_code');
    const country = this.getComponent(addressComponents, 'country');

    let city = '';
    const cityCodes = [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'administrative_area_level_3',
      'neighborhood',
    ];

    // Find the first locality
    cityCodes.some(cc => {
      const ac = addressComponents.find(c => c.types.includes(cc));
      if (ac) {
        city = ac.long_name;
        return true;
      }
    });

    return {
      street: `${streetNumber.long_name} ${streetAddress.long_name}`,
      city,
      province: province.short_name,
      postal: postal.long_name,
      country: country.short_name,
    };
  }
}
