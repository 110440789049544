import { CREATE_USER, DISABLE_USER, UPDATE_USER, ACTIVATE_USER } from '@/graphql/mutations';
import { GET_USERS, GET_USERS_BY_ROLE } from '@/graphql/queries';
import { IUser } from '@/interfaces';
import { IPaginatedVariables } from '@/interfaces/IPageInfo.interface';
import { Vue, Component } from 'vue-property-decorator';

@Component
export class UsersMixin extends Vue {
  async getUsers(variables: IPaginatedVariables = { first: 50, search: `%%` }) {
    const { data } = await this.$apollo.query({
      query: GET_USERS,
      variables,
      fetchPolicy: 'network-only',
    });

    return data;
  }

  async getUsersByRole(
    role: string = 'Customer',
    variables: IPaginatedVariables = { first: 50, search: `%%` },
  ) {
    const { data } = await this.$apollo.query({
      query: GET_USERS_BY_ROLE,
      variables: {
        ...variables,
        role,
      },
      fetchPolicy: 'network-only',
    });

    return data;
  }

  async createUser(user: Partial<IUser>) {
    const { data } = await this.$apollo.mutate({
      mutation: CREATE_USER,
      variables: user,
    });

    return data.createOneUser;
  }

  async updateUser(user: Partial<IUser>) {
    try {
      const { data } = await this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: user,
      });

      return data.updateOneUser;
    } catch (e) {
      const message = e.message?.includes('DUP_ENTRY')
        ? 'Email aleady in use'
        : 'Unable to save user';

      this.$notify({
        group: 'main',
        type: 'error',
        title: 'Error',
        text: message,
      });
    }
  }

  async disableUser(id: number) {
    const { data } = await this.$apollo.mutate({
      mutation: DISABLE_USER,
      variables: { id },
    });

    return data.updateOneUser;
  }
  async activateUser(id: number) {
    const { data } = await this.$apollo.mutate({
      mutation: ACTIVATE_USER,
      variables: { id },
    });

    return data.updateOneUser;
  }
}
