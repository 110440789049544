





























import { Component, Prop, Emit, Watch, Mixins } from 'vue-property-decorator';
import { IUser } from '@/interfaces';
import { UsersMixin } from '@/mixins';

@Component
export class UserSelect extends Mixins(UsersMixin) {
  @Prop() type!: string;
  @Prop() selectedUser!: IUser;

  private search = '';
  private searching = false;
  private users: IUser[] = [];
  private user: IUser = null!;

  private creatingUser = false;

  get filteredUsers() {
    if (this.type) {
      return this.users.filter(u => u.role === this.type && u.isActive);
    }
    return this.users;
  }

  created() {
    if (this.selectedUser) {
      this.user = this.selectedUser;
    }
    this.refreshUsers();
  }

  async refreshUsers() {
    const { users } = await this.getUsersByRole(this.type);
    this.users = users.edges.map(u => u.node);
  }

  customFilter(item: IUser, queryText, itemText) {
    const phone = item.phone.replace('(', '').replace(')', '').replaceAll(' ', '').replace('-', '');
    const simplifiedValue = `${item.firstname.toLowerCase()} ${item.lastname.toLowerCase()} ${phone}`;

    const searchText = queryText.toLowerCase();

    return simplifiedValue.indexOf(searchText) > -1;
  }

  @Watch('search')
  async onSearch() {
    if ((this.search && this.search.length > 3) || this.search === '') {
      const data = await this.getUsersByRole(this.type, { search: this.search });
      this.users = data.users.edges;
    }
  }

  @Watch('user')
  @Emit('onSelected')
  onSelected() {
    if (this.user) {
      return Object.assign({}, this.user);
    }
  }

  onUserCreated(user: IUser) {
    this.user = user;
    this.refreshUsers();
    this.creatingUser = false;
  }

  usersName(item) {
    if (this.type === 'Driver') {
      return `${item.firstname} ${item.lastname}`;
    }
    return `${item.firstname} ${item.lastname} - ${item.phone}`;
  }
}
export default UserSelect;
