import gql from 'graphql-tag';

export const GET_DELIVERIES = gql`
  query getDeliveries(
    $first: Int
    $last: Int
    $after: ConnectionCursor
    $before: ConnectionCursor
    $search: String
  ) {
    deliveries(
      filter: { or: [{ products: { like: $search } }] }
      paging: { first: $first, after: $after, last: $last, before: $before }
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          customer {
            id
            firstname
            lastname
            email
            phone
            address
            city
            province
            postal
            country
          }
          driver {
            id
            firstname
            lastname
            email
            phone
          }
          products
          paymentMethod
          deliveryNotes
          status
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_Delivery = gql`
  query getDelivery($id: ID!) {
    delivery(id: $id) {
      id
      customer {
        id
        firstname
        lastname
        email
        phone
        address
        city
        province
        postal
        country
      }
      driver {
        id
        firstname
        lastname
        email
        phone
      }
      products
      paymentMethod
      deliveryNotes
      status
    }
  }
`;

export const GET_PAST_DELIVERIES = gql`
  query getPastDeliveries(
    $first: Int
    $last: Int
    $after: ConnectionCursor
    $before: ConnectionCursor
    $search: String
    $sortBy: DeliverySortFields!
    $sortDir: SortDirection!
  ) {
    deliveries(
      filter: {
        and: [
          {
            or: [
              { products: { like: $search } }
              { customer: { firstname: { like: $search } } }
              { customer: { lastname: { like: $search } } }
              { customer: { address: { like: $search } } }
              { driver: { firstname: { like: $search } } }
              { driver: { lastname: { like: $search } } }
              { driver: { lastname: { like: $search } } }
            ]
          }
          { status: { eq: "Completed" } }
        ]
      }
      paging: { first: $first, after: $after, last: $last, before: $before }
      sorting: [{ field: $sortBy, direction: $sortDir }]
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          customer {
            id
            firstname
            lastname
            email
            phone
            address
            city
            province
            postal
            country
          }
          driver {
            id
            firstname
            lastname
            email
            phone
          }
          products
          paymentMethod
          deliveryNotes
          status
          createdBy {
            id
            firstname
            lastname
          }
          deliveredAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
