import * as VueGoogleMaps from 'gmap-vue';
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import App from '@/App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Notifications from 'vue-notification';
import velocity from 'velocity-animate';
import CustomComponents from './install';
import { createProvider } from './vue-apollo';
import { AuthStore } from './store/modules/auth.store';

Vue.config.productionTip = false;

Vue.use(Notifications, { velocity });
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places',
  },
});

Vue.use(CustomComponents);
AuthStore.init();

new Vue({
  router,
  store,
  vuetify,
  created: () => {},
  apolloProvider: createProvider(),
  render: h => h(App),
}).$mount('#app');
