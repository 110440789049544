













































































import { Component, Prop, Mixins, Emit, Vue, Watch } from 'vue-property-decorator';
import { IUser } from '@/interfaces';
import { DeliveriesMixin } from '@/mixins';
import { mask } from 'vue-the-mask';
import { IDelivery } from '@/interfaces/IDelivery.interface';

@Component({
  directives: {
    mask,
  },
})
export class DeliveryCreator extends Mixins(DeliveriesMixin) {
  @Prop({ default: false }) showDialog!: boolean;
  @Prop({ default: false }) editing!: boolean;
  @Prop({ default: '80%' }) width!: string;
  @Prop() delivery!: IDelivery;

  // Used while saving (during API call)
  private processing: boolean = false;
  // Validator for input form
  private valid = false;
  // Sets the editor to new or updating
  private isEditing: boolean = false;
  // Main Data transfer object for the client data

  private paymentOptions = ['Cash', 'Debit', 'Credit'];
  private deliveryDto: Partial<IDelivery> = {
    paymentMethod: 'Cash',
  };

  // Form rules
  private rules = {
    customer: [v => !!v || 'Customer is required'],
    products: [v => !!v || 'Products are required'],
    payment: [v => !!v || 'Payment Method is required'],
  };

  // Created
  created() {
    // Checks if editing prop was passed in
    if (this.editing) {
      this.isEditing = true;
      this.deliveryDto = Object.assign({}, this.delivery);
    }
  }

  @Watch('delivery')
  onDeliveryChanged() {
    this.deliveryDto = this.delivery;
    if (this.deliveryDto.customer) {
      this.delivery.customerId = this.delivery.customer.id;
    }
  }

  onCustomerSelected(customer: IUser) {
    if (customer) {
      this.deliveryDto.customerId = customer.id;
      Vue.set(this.deliveryDto, 'customer', customer);
    }
  }
  onDriverSelected(driver: IUser) {
    this.deliveryDto.driverId = driver.id;
    this.deliveryDto.driver = driver;
  }

  isValid() {
    const formRef = this.$refs.form as HTMLFormElement;
    formRef.validate();

    if (this.valid) {
      return true;
    }
    return false;
  }

  // Methods
  async onCreate() {
    if (this.isValid()) {
      if (this.deliveryDto) {
        try {
          const delivery = await this.createDelivery(this.deliveryDto);
          if (delivery) {
            this.onSaved(delivery);
          }
        } catch (e) {
          this.processing = false;
          this.$notify({
            group: 'main',
            type: 'error',
            title: 'Error',
            text: 'Unable to create delivery',
          });
        }
      } else {
        this.processing = false;
      }
    } else {
      this.$notify({
        group: 'main',
        type: 'warn',
        title: 'Fields Missing',
        text: 'Please fill in all required fields',
      });
      this.processing = false;
    }
  }

  async onUpdate() {
    if (this.isValid()) {
      try {
        const delivery = await this.updateDelivery(this.deliveryDto);
        if (delivery) {
          this.onSaved(delivery);
        }
      } catch (e) {
        this.processing = false;
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Error',
          text: 'Unable to update delivery',
        });
      }
    } else {
      this.$notify({
        group: 'main',
        type: 'warn',
        title: 'Fields Missing',
        text: 'Please fill in all required fields',
      });
      this.processing = false;
    }
  }

  onSave() {
    this.processing = true;
    if (this.isEditing) {
      this.onUpdate();
    } else {
      this.onCreate();
    }
  }

  resetEditor() {
    if (!this.isEditing) {
      const formRef = this.$refs.form as HTMLFormElement;
      formRef.reset();
      this.deliveryDto = {
        products: '',
        paymentMethod: 'Cash',
        deliveryNotes: '',
      };
      this.processing = false;
    }
  }

  @Emit('onSaved')
  onSaved(delivery: IDelivery) {
    this.$notify({
      group: 'main',
      type: 'success',
      title: this.isEditing ? 'Delivery Updated' : 'Delivery Created',
      text: '',
    });
    this.resetEditor();
    return delivery;
  }

  @Emit('onCancel')
  onCancel() {
    this.resetEditor();
  }
}
export default DeliveryCreator;
