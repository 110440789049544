



















































































































































import { Component, Prop, Vue, Mixins, Emit, Watch } from 'vue-property-decorator';
import { IUser } from '@/interfaces';
import { GoogleMapsMixin } from '@/mixins';
import { mask } from 'vue-the-mask';
import { UsersMixin } from '@/mixins/users.mixin';

@Component({
  directives: {
    mask,
  },
})
export class UserEditor extends Mixins(GoogleMapsMixin, UsersMixin) {
  @Prop({ default: false }) showDialog!: boolean;
  @Prop({ default: false }) editing!: boolean;
  @Prop({ default: '80%' }) width!: string;
  @Prop() user: IUser;

  private formRef;
  // Used while saving (during API call)
  private processing: boolean = false;
  // Validator for input form
  private valid = false;
  // Sets the editor to new or updating
  private isEditing: boolean = false;
  // Main Data transfer object for the client data
  private userDto: Partial<IUser> = {
    address: '',
    city: '',
    province: '',
    postal: '',
    country: '',
    role: 'Customer',
  };

  // Form rules
  private rules = {
    name: [v => !!v || 'Full name is required'],
    email: [
      v => !!v || 'Email is required',
      v => /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'Invalid Email',
    ],
    password: [v => !!v || 'Password is required'],
    phone: [v => !!v || 'Phone is required'],
    address: [v => !!v || 'Address is required'],
    city: [v => !!v || 'City is required'],
    province: [v => !!v || 'Province is required'],
    postal: [v => !!v || 'Postal Code is required'],
    country: [v => !!v || 'Country is required'],
  };

  // Created
  created() {
    // Checks if editing prop was passed in
    if (this.editing !== false) {
      this.isEditing = true;
    }
    if (this.user) {
      this.userDto = Object.assign({}, this.user);
    }
  }

  @Watch('user')
  onDeliveryChanged() {
    this.userDto = Object.assign({}, this.user);
  }

  // Methods
  onAutoComplete(googleData: google.maps.GeocoderResult) {
    const location = this.addressComponentsToLocation(googleData.address_components);
    this.userDto.address = location.street;
    this.userDto.city = location.city;
    this.userDto.province = location.province;
    this.userDto.postal = location.postal;
    this.userDto.country = location.country;
  }

  isValid() {
    const formRef = this.$refs.form as HTMLFormElement;
    formRef.validate();

    if (this.valid) {
      return true;
    }
    return false;
  }

  async onCreate() {
    if (this.isValid()) {
      try {
        if (this.userDto.role === 'Customer') {
          this.userDto.password = '4u3b43809f34bfi';
        }
        const user = await this.createUser(this.userDto);
        this.onSaved(user);
      } catch (e) {
        const message = e.message?.includes('DUP_ENTRY')
          ? 'Email aleady in use'
          : 'Unable to save user';

        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Error',
          text: message,
        });
        this.processing = false;
      }
    } else {
      this.processing = false;
    }
  }

  async onUpdate() {
    if (this.isValid()) {
      const user = await this.updateUser(this.userDto);
      if (user) {
        this.onSaved(user);
      }
      this.processing = false;
    } else {
      this.processing = false;
      this.$notify({
        group: 'main',
        type: 'warn',
        title: 'Fields Missing',
        text: 'Please fill in all required fields',
      });
    }
  }

  onSave() {
    this.processing = true;
    if (this.isEditing) {
      this.onUpdate();
    } else {
      this.onCreate();
    }
  }

  resetEditor() {
    const formRef = this.$refs.form as HTMLFormElement;
    formRef.reset();
    this.userDto = {
      address: '',
      city: '',
      province: '',
      postal: '',
      country: '',
      role: 'Customer',
    };
    this.processing = false;
  }

  // Events
  @Emit('onSaved')
  onSaved(user: IUser) {
    this.$notify({
      group: 'main',
      type: 'success',
      title: this.editing ? 'User Updated' : 'User Saved',
      text: '',
    });
    this.resetEditor();
    return user;
  }

  @Emit('onClose')
  onClose() {
    this.resetEditor();
  }
}
export default UserEditor;
