import store from '@/store';
import { Module, VuexModule, getModule, Action, Mutation } from 'vuex-module-decorators';
import { apolloClient } from '@/vue-apollo';
import { IDelivery } from '@/interfaces/IDelivery.interface';
import { GET_DELIVERIES } from '@/graphql/queries';
import { IUser } from '@/interfaces';

@Module({
  namespaced: true,
  name: 'Deliveries',
  store,
  dynamic: true,
})
class DeliveriesStoreModule extends VuexModule {
  private deliveries: IDelivery[] = [];

  get unassignedDeliveries() {
    return this.deliveries.filter(d => d.driver === null);
  }

  get onRouteDeliveries() {
    return this.deliveries.filter(d => d.driver !== null && d.status !== 'Completed');
  }

  get completedDeliveries() {
    return this.deliveries.filter(d => d.status === 'Completed');
  }

  @Action
  async addDelivery(delivery: IDelivery) {
    this.ADD_DELIVERY(delivery);
  }

  @Action
  async updateDelivery(delivery: IDelivery) {
    this.UPDATE_DELIVERY(delivery);
  }

  @Mutation
  SET_DELIVERIES(deliveries: IDelivery[]) {
    this.deliveries = deliveries;
  }

  @Mutation
  ADD_DELIVERY(delivery: IDelivery) {
    this.deliveries.push(delivery);
  }

  @Mutation
  UPDATE_DELIVERY(delivery: IDelivery) {
    const oldDelivery = this.deliveries.find(d => d.id === delivery.id);
    if (oldDelivery) {
      oldDelivery.driver = delivery.driver;
      oldDelivery.driverId = delivery.driverId;
    }
  }
}

export const DeliveriesStore = getModule(DeliveriesStoreModule);
