/**
 * Automatically installs all components at runtime
 */

import { VueConstructor } from 'vue';
import * as components from '@/components';

export default {
  install(Vue: VueConstructor) {
    if (components) {
      for (const key in components) {
        const component = components[key];
        if (component) {
          Vue.component(key, component as typeof Vue);
        }
      }
      return true;
    }
    return false;
  },
};
