import gql from 'graphql-tag';

export const AUTH = gql`
  mutation auth($email: String!, $password: String!) {
    auth(email: $email, password: $password) {
      user {
        id
        email
        firstname
        lastname
        role
      }
      token
    }
  }
`;

export const ME = gql`
  query getAuthUser {
    me {
      id
      email
      firstname
      lastname
      role
    }
  }
`;
