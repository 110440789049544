

























































import { Component, Prop, Mixins, Emit, Watch } from 'vue-property-decorator';
import { DeliveriesMixin } from '@/mixins';
import { IDelivery } from '@/interfaces/IDelivery.interface';
import { DeliveriesStore } from '@/store/modules/deliveries.store';

@Component
export class DeliveryCard extends Mixins(DeliveriesMixin) {
  @Prop({ required: true }) delivery: IDelivery;
  @Prop() rerender;

  // Created
  created() {}

  get urgency() {
    const durationInMinutes =
      (new Date().getTime() - new Date(this.delivery.createdAt).getTime()) / 1000 / 60;

    if (durationInMinutes < 20) {
      return 'new';
    } else if (durationInMinutes > 20 && durationInMinutes < 40) {
      return 'warn';
    }

    return 'late';
  }

  async onCompleted() {
    try {
      await this.completeDelivery(this.delivery.id);
      this.$notify({
        group: 'main',
        type: 'success',
        title: 'Delivery Completed',
        text: 'Delivery was completed',
      });
      this.onCompletedDelivery();
    } catch (e) {
      this.$notify({
        group: 'main',
        type: 'error',
        title: 'Error',
        text: 'Unable to complete delivery',
      });
    }
  }

  onDriverAssigned(delivery: IDelivery) {
    if (delivery.driver) {
      DeliveriesStore.updateDelivery(delivery);
      this.sendNotificationToDriver(delivery.id);
    }
  }

  async resendNotification() {
    try {
      await this.sendNotificationToDriver(this.delivery.id);
      this.$notify({
        group: 'main',
        type: 'success',
        title: 'Sent',
        text: 'Delivery was sent to the driver',
      });
    } catch (e) {
      this.$notify({
        group: 'main',
        type: 'error',
        title: 'Error',
        text: 'Unable to send delivery to the driver',
      });
    }
  }

  @Watch('rerender')
  onRerender() {
    this.$forceUpdate();
  }

  @Emit('onEditDelivery')
  onEditDelivery() {
    return this.delivery;
  }
  @Emit('onCompletedDelivery')
  onCompletedDelivery() {
    return this.delivery;
  }
}
export default DeliveryCard;
