import gql from 'graphql-tag';

export const GET_USERS = gql`
  query getUsers($search: String) {
    users(
      filter: {
        or: [
          { firstname: { like: $search } }
          { lastname: { like: $search } }
          { email: { like: $search } }
          { phone: { like: $search } }
          { address: { like: $search } }
          { city: { like: $search } }
        ]
      }
      sorting: { field: firstname, direction: ASC }
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          firstname
          lastname
          email
          phone
          address
          city
          province
          postal
          country
          role
          isActive
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      firstname
      lastname
      email
      phone
      address
      city
      province
      postal
      country
      isActive
    }
  }
`;

export const GET_USERS_BY_ROLE = gql`
  query getUsersByRole(
    $first: Int
    $last: Int
    $after: ConnectionCursor
    $before: ConnectionCursor
    $search: String
    $role: String!
  ) {
    users(
      filter: {
        and: [
          { role: { eq: $role } }
          {
            or: [
              { firstname: { like: $search } }
              { lastname: { like: $search } }
              { email: { like: $search } }
              { phone: { like: $search } }
              { address: { like: $search } }
              { city: { like: $search } }
            ]
          }
        ]
      }
      paging: { first: $first, after: $after, last: $last, before: $before }
      sorting: { field: firstname, direction: ASC }
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          firstname
          lastname
          email
          phone
          address
          city
          province
          postal
          country
          role
          isActive
        }
      }
    }
  }
`;
