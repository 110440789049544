


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { IUser } from '@/interfaces';

@Component
export class UserCard extends Vue {
  @Prop() user: IUser;

  // Created
  created() {}
}
export default UserCard;
