import gql from 'graphql-tag';

export const CREATE_DELIVERY = gql`
  mutation CreateDelivery(
    $customerId: Int!
    $driverId: Int
    $products: String!
    $paymentMethod: String!
    $deliveryNotes: String
  ) {
    createOneDelivery(
      input: {
        delivery: {
          customerId: $customerId
          driverId: $driverId
          products: $products
          paymentMethod: $paymentMethod
          deliveryNotes: $deliveryNotes
        }
      }
    ) {
      id
      customer {
        id
        firstname
        lastname
        email
        phone
        address
        city
        province
        postal
        country
      }
      driver {
        id
        firstname
        lastname
        email
        phone
      }
      products
      paymentMethod
      deliveryNotes
      status
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_DELIVERY = gql`
  mutation UpdateDelivery(
    $id: ID!
    $customerId: Int!
    $driverId: Int
    $products: String!
    $paymentMethod: String!
    $deliveryNotes: String
  ) {
    updateOneDelivery(
      input: {
        id: $id
        update: {
          customerId: $customerId
          driverId: $driverId
          products: $products
          paymentMethod: $paymentMethod
          deliveryNotes: $deliveryNotes
        }
      }
    ) {
      id
      customer {
        id
        firstname
        lastname
        email
        phone
        address
        city
        province
        postal
        country
      }
      driver {
        id
        firstname
        lastname
        email
        phone
      }
      products
      paymentMethod
      deliveryNotes
      status
      createdAt
      updatedAt
    }
  }
`;

export const ASSIGN_DRIVER = gql`
  mutation AssignDriver($deliveryId: ID!, $driverId: Int!) {
    updateOneDelivery(input: { id: $deliveryId, update: { driverId: $driverId } }) {
      id
      customer {
        id
        firstname
        lastname
        email
        phone
        address
        city
        province
        postal
        country
      }
      driver {
        id
        firstname
        lastname
        email
        phone
      }
      products
      paymentMethod
      deliveryNotes
      status
      createdAt
      updatedAt
    }
  }
`;

export const COMPLETE_DELIVERY = gql`
  mutation CompleteDelivery($deliveryId: ID!) {
    updateOneDelivery(input: { id: $deliveryId, update: { status: "Completed" } }) {
      id
      customer {
        id
        firstname
        lastname
        email
        phone
        address
        city
        province
        postal
        country
      }
      driver {
        id
        firstname
        lastname
        email
        phone
      }
      products
      paymentMethod
      deliveryNotes
      status
      createdAt
      updatedAt
    }
  }
`;

export const REOPEN_DELIVERY = gql`
  mutation ReopenDelivery($deliveryId: ID!) {
    updateOneDelivery(input: { id: $deliveryId, update: { status: "Open", driverId: null } }) {
      id
      customer {
        id
        firstname
        lastname
        email
        phone
        address
        city
        province
        postal
        country
      }
      driver {
        id
        firstname
        lastname
        email
        phone
      }
      products
      paymentMethod
      deliveryNotes
      status
      createdAt
      updatedAt
    }
  }
`;

export const SEND_NOTIFICATION_TO_DRIVER = gql`
  mutation($deliveryId: Int!) {
    sendToDriver(deliveryId: $deliveryId) {
      deliveryId
      message
    }
  }
`;
