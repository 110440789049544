




























import { Component, Prop, Mixins, Emit, Vue } from 'vue-property-decorator';
import { IUser } from '@/interfaces';
import { DeliveriesMixin } from '@/mixins';
import { IDelivery } from '@/interfaces/IDelivery.interface';

@Component
export class AssignDriverModal extends Mixins(DeliveriesMixin) {
  @Prop({ required: true }) delivery!: IDelivery;

  private showDialog = false;
  private processing: boolean = false;
  private driver: Partial<IUser> = {};

  // Created
  created() {}

  onDriverSelected(driver: IUser) {
    this.driver = driver;
  }

  async onSave() {
    if (this.driver && this.driver.id) {
      this.processing = true;
      try {
        const delivery = await this.assignDriver(this.delivery.id, this.driver.id);
        this.onAssigned(delivery);
      } catch (e) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Error',
          text: 'Unable to assign delivery',
        });
      }
    }
  }

  resetEditor() {
    this.driver = {};
    this.processing = false;
  }

  @Emit('onAssigned')
  onAssigned(delivery: IDelivery) {
    this.$notify({
      group: 'main',
      type: 'success',
      title: 'Driver Assigned',
      text: '',
    });
    return delivery;
  }

  @Emit('onCancel')
  onCancel() {
    this.resetEditor();
  }
}
export default AssignDriverModal;
