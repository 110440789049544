import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#343434',
        secondary: '#8CA62D',
        accent: colors.shades.black,
        error: '#A61717',
        orange: '#D97014',
        yellow: '#D9A918',
        background: '#f5f6f7',
        text: {
          color: '#aaa',
        }
      },
      dark: {
        primary: colors.blue.darken3,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        info: colors.lightBlue.darken2,
        error: colors.red.darken4,
        background: '#222222'
      }
    }
  },
});
