import { IUser } from '../../interfaces/IUser.interface';
import store from '@/store';
import { Module, VuexModule, getModule, Action, Mutation } from 'vuex-module-decorators';
import { onLogin, onLogout, apolloClient } from '@/vue-apollo';
import { AUTH, ME } from '@/graphql/mutations/Auth.mutations';

@Module({
  namespaced: true,
  name: 'Auth',
  store,
  dynamic: true,
})
class AuthStoreModule extends VuexModule {
  private token: string = '';
  private user: IUser | null = null;

  get isAuthenticated() {
    return this.token!!;
  }

  get authenticatedUser() {
    return this.user;
  }

  // Actions
  @Action({ rawError: true })
  async init() {
    const token = localStorage.getItem('token');

    if (token) {
      this.SET_TOKEN(token);
      await this.getUser();
    }
    return;
  }

  @Action({ rawError: true })
  async login({ email, password }) {
    const { data } = await apolloClient.mutate({
      mutation: AUTH,
      variables: {
        email,
        password,
      },
    });

    this.SET_TOKEN(data.auth.token);
    onLogin(apolloClient, data.auth.token);
  }

  @Action
  async logout() {
    this.SET_TOKEN('');
    this.SET_USER(null);
    onLogout(apolloClient);
  }

  @Action({ rawError: true })
  async getUser() {
    const { data } = await apolloClient.query({
      query: ME,
    });

    this.SET_USER(data.me);
  }

  @Mutation
  SET_TOKEN(token: string) {
    this.token = token;
  }
  @Mutation
  SET_USER(user: IUser | null) {
    this.user = user;
  }
}

export const AuthStore = getModule(AuthStoreModule);
