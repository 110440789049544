




































































































import { IDelivery } from '@/interfaces/IDelivery.interface';
import { Component, Vue } from 'vue-property-decorator';
import { AuthStore } from '../store/modules/auth.store';
import { DeliveriesStore } from '../store/modules/deliveries.store';

@Component
export default class HomeView extends Vue {
  private loading = true;
  private creatingDelivery = false;
  private drawer = false;

  created() {}

  onDeliveryCreated(delivery: IDelivery) {
    DeliveriesStore.addDelivery(delivery);
    this.creatingDelivery = false;
  }

  onNavigate(link: string) {
    this.$router.push(link);
  }

  onLogout() {
    AuthStore.logout();
    this.$router.push('login');
  }
}
