import gql from 'graphql-tag';

export const CREATE_USER = gql`
  mutation CreateUser(
    $firstname: String!
    $lastname: String!
    $password: String!
    $email: String!
    $phone: String!
    $address: String!
    $city: String!
    $province: String!
    $postal: String!
    $country: String!
    $role: String!
  ) {
    createOneUser(
      input: {
        user: {
          firstname: $firstname
          lastname: $lastname
          password: $password
          email: $email
          phone: $phone
          address: $address
          city: $city
          province: $province
          postal: $postal
          country: $country
          role: $role
        }
      }
    ) {
      id
      firstname
      lastname
      email
      phone
      address
      city
      province
      postal
      country
      role
      isActive
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $firstname: String!
    $lastname: String!
    $email: String!
    $phone: String!
    $address: String!
    $city: String!
    $province: String!
    $postal: String!
    $country: String!
    $role: String!
  ) {
    updateOneUser(
      input: {
        id: $id
        update: {
          firstname: $firstname
          lastname: $lastname
          email: $email
          phone: $phone
          address: $address
          city: $city
          province: $province
          postal: $postal
          country: $country
          role: $role
        }
      }
    ) {
      id
      firstname
      lastname
      email
      phone
      address
      city
      province
      postal
      country
      role
      isActive
    }
  }
`;

export const DISABLE_USER = gql`
  mutation DisableUser($id: ID!) {
    updateOneUser(input: { id: $id, update: { isActive: false } }) {
      id
      firstname
      lastname
      email
      phone
      address
      city
      province
      postal
      country
      role
      isActive
    }
  }
`;

export const ACTIVATE_USER = gql`
  mutation ActivateUser($id: ID!) {
    updateOneUser(input: { id: $id, update: { isActive: true } }) {
      id
      firstname
      lastname
      email
      phone
      address
      city
      province
      postal
      country
      role
      isActive
    }
  }
`;
